import React from 'react';

// import Navbar from '../components/Navbar';
import ResponsiveContainer from '../components/ResponsiveContainer';

const Login = () => {
    return (
        <ResponsiveContainer>
            Login
        </ResponsiveContainer>
    )
}

export default Login;
