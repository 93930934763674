/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment
} from 'semantic-ui-react';

import ResponsiveContainer from '../components/ResponsiveContainer';

import KaynakliImalat from '../images/kaynakli_imalat.jpg';
import LazerKesim from '../images/lazer_kesim.jpeg';
import AbkantBukum from '../images/abkand_bukum.jpeg';
import Tasarim from '../images/tasarim.webp';
import BannerVideo from '../images/laser_video.mp4';


const Home = () => (
  <ResponsiveContainer showBanner={true} >
    <Segment style={{ padding: '8em 0em' }} vertical raised primary>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as='h2' style={{ fontSize: '3.5em' }}>
            Nitelikli ürün üretme ve çözüm odaklı çalışma
            </Header>
            <p style={{ fontSize: '1.73em' }}>
            Firmamız kuruluş itibarı ile sektörde bulunan nitelikli ürün üretme
            ve çözüm odaklı çalışma prensibine yeni bir bakış açısı kazandırmak istemektedir.
            Zamanın ve hammaddenin değerinin farkında olarak lazer kesim,
            abkant büküm, kaynaklı imalat, proje bazlı işler ile tasarım alanında hem
            yurtiçi hem de yurtdışı hizmet vermek amacı ile hareket etmektedir.
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            {/* <Image bordered rounded size='large' src={KaynakliImalat} /> */}
            <video className='videoTag' autoPlay loop muted width={600} height={300}>
                <source src={BannerVideo} type='video/mp4' />
            </video>

          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
      </Grid>
    </Segment>

    {/* <Segment style={{ padding: '0em' }} vertical>
      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row textAlign='center' color='orange'>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              "What a Company"
            </Header>
            <p style={{ fontSize: '1.33em' }}>That is what they all say about us</p>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              "I shouldn't have gone with their competitor."
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              <Image avatar src={Tolga} />
              <b>Tolga</b> Lead Data Scientist - ADP
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment> */}

    <Segment style={{ padding: '1em 0em'}} vertical stacked secondary >
      <Container text>
      <Divider
          as='h2'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Lazer Kesim</a>
        </Divider>
        <Grid  stackable verticalAlign='middle'>
        <Grid.Row >
          <Grid.Column width={10}>
          <p style={{ fontSize: '1.53em' }}>
        Akka Demir Çelik olarak, endüstrinin gelişimini takip ederek son teknoloji lazer kesim
         tezgahlarımızla hizmet vermekteyiz. Bu doğrultuda
        </p>
        <p style={{ fontSize: '1.23em' }}>
        <ul>
          <li>HRP-DKP-ST37-52 SAC : 0.5mm - 20mm</li>
          <li>Paslanmaz Sac : 0.5mm - 10mm</li>
          <li>Alüminyum Sac : 0.5mm - 10mm</li>
          <li>Hardox Sac : 0.5mm - 20mm</li>
        </ul>

        <p style={{ fontSize: '.85em' }}>Maksimum 1500x3000 sac işleme kapasitesine sahibiz.</p>

        </p>
            
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src={LazerKesim} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
      </Grid>
        
        {/* <Button as='a' size='large'>
          Read More
        </Button> */}

        <Divider
          as='h2'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Abkant Büküm</a>
        </Divider>
        <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={10}>
            <p style={{ fontSize: '1.53em' }}>
              Hassasiyet değeri yüksek abkant pres tezgahlarımız sayesinde tolerans
              değerlerine uygun büküm hizmeti verebilmekteyiz. 
              </p >
        <p style={{ fontSize: '1em' }}>Maksimum 3000 mm boyunda bükme kapasitesi.</p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src={AbkantBukum} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
      </Grid>
      <Divider
          as='h2'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Kaynaklı İmalat</a>
        </Divider>
        <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={10}>
            <p style={{ fontSize: '1.73em' }}>
            Kaynak hattımız ve konusunda uzman personelimiz ile otomotiv, makine,
             raylı sistemler, savunma sanayi ve mobilya sektörlerine hizmet vermekteyiz. 
              </p >
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src={KaynakliImalat} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
      </Grid>
      <Divider
          as='h2'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Tasarim</a>
        </Divider>
        <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={10}>
            <p style={{ fontSize: '1.73em' }}>
            2D ve 3D modelleme, tasarım ve modellenmiş her türlü veriyi üretebilme tecrübe ve
             yeteneğimiz bulunmaktadır. 
              </p >
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src={Tasarim} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
      </Grid>
        {/* <Button as='a' size='large'>
          I'm Still Quite Interested
        </Button> */}
      </Container>
    </Segment>
  </ResponsiveContainer>
)

export default Home