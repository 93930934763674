import React, { useState } from 'react';
import {
    Segment,
    Visibility,
  } from 'semantic-ui-react';

  import Banner from './Banner';
  import Navbar from './Navbar';
  import BannerImage from '../images/lazer_kesim.jpeg';
  import BannerVideo from '../images/laser_video.mp4';

const DesktopContainer =  ({children, showBanner}) => {
    
    const [fixed, setFixed] = useState(false);
    
    const hideFixedMenu = () => setFixed(false);
    const showFixedMenu = () => setFixed(true );
    
      return (
          <>
          {
            showBanner?
              (
                <Visibility
                    once={false}
                    onBottomPassed={showFixedMenu}
                    onBottomPassedReverse={hideFixedMenu}
                    // style={{border: "3px solid red"}}
                >
                    <Segment
                    inverted
                    textAlign='center'
                    style={{ minHeight: 600, padding: '0em 0em', backgroundImage: `url(${BannerImage})`,
                    backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}
                    vertical
                    >
                      
                        <Navbar fixed={fixed}/>
                        {/* <video className='videoTag' autoPlay loop muted>
                          <source src={BannerVideo} type='video/mp4' />
                      </video> */}
                        {/* <Banner /> */}
                    </Segment>
                </Visibility>
              ):
              (
                <Segment
                textAlign='center'
                style={{ padding: '1em 0em'}}
                vertical
                >
                    <Navbar fixed={true} />
                </Segment>
              )
            }
                {children}
          </>
      )
  }
  

  export default DesktopContainer;