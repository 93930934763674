import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Header,
  Image
} from 'semantic-ui-react';
import logo from '../images/logo.svg';
import Banner from './Banner';
import BannerImage from '../images/lazer_kesim.jpeg';;


const MobileContainer = ({children, showBanner}) => {
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const [activeButton, setActiveButton] = useState({ activeItem: "home" });

    const handleSidebarHide = () => setSidebarOpened( false );
  
    const handleToggle = () => setSidebarOpened( true );

    const history = useHistory();


    const handleItemClick = (e, { name }) => {
        setActiveButton({ activeItem: name });
        // console.log(currentUser)
        // if (name === "login" && currentUser) {
        if (name === "login" ) {
        //   logout();
          history.push(`/`);
        } else if (name === "home") {
          history.push(`/`);
        } else {
          history.push(`/${name}`);
        }
      };
  
      return (
          <Sidebar.Pushable >
            <Sidebar
              as={Menu}
              animation='overlay'
              inverted
              onHide={handleSidebarHide}
              vertical
              visible={sidebarOpened}
            >
              <Menu.Item as='a' name = "home" onClick={handleItemClick} active>Home</Menu.Item>
              <Menu.Item as='a' name = "company" onClick={handleItemClick}>Company</Menu.Item>
              <Menu.Item as='a' name = "services" onClick={handleItemClick}>Services</Menu.Item>
              <Menu.Item as='a' name = "team" onClick={handleItemClick}>Team</Menu.Item>
              <Menu.Item as='a' name = "contact" onClick={handleItemClick}>Contact</Menu.Item>
            </Sidebar>
            <Sidebar.Pusher dimmed={sidebarOpened} >
                
              {
                  showBanner?
                  (
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ minHeight: 350, padding: '1em 0em', backgroundImage: `url(${BannerImage})`,
                        backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        }}
                        vertical
                    >
                      
                        <Container style={{ display: 'flex', alignItems: 'left' }}>
                        <Menu borderless="true" floated='right' inverted pointing secondary size='massive'>
                            <Menu.Item onClick={handleToggle}>
                              <Icon name='sidebar' />
                            </Menu.Item>
                            <Menu.Item position='right'>
                            
                            {/* <Button as='a' inverted>
                                Log in
                            </Button>
                            <Button as='a' inverted style={{ marginLeft: '0.5em' }}>
                                Sign Up
                            </Button> */}
                            </Menu.Item>
                        </Menu>
                        <Container style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ marginRight: '1em' }}>
                                <Image src={logo} size='tiny' alt='Company Logo' />
                              </div>
                              <div>
                                <Header inverted as='h2'>AKKA DEMIR CELIK</Header>
                              </div>
                            </Container>
                        </Container>
                        {/* <Banner mobile /> */}
                    </Segment>
                  )
                  :
                  (
                      <>
                        <Container >
                        <Menu  pointing secondary size='large'>
                            <Menu.Item onClick={handleToggle}>
                            <Icon name='sidebar' />
                            </Menu.Item>
                            <Menu.Item position='right'>
                            <Button as='a'>
                                Log in
                            </Button>
                            <Button as='a' style={{ marginLeft: '0.5em' }}>
                                Sign Up
                            </Button>
                            </Menu.Item>
                        </Menu>
                        </Container>
                        </>
                  )
              }
              {children}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
      )
  }
  

  export default MobileContainer;