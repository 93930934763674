import React from 'react';
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Image,
    Segment,
  } from 'semantic-ui-react';
//   import { Document } from 'react-pdf';

// import Navbar from '../components/Navbar';
import ResponsiveContainer from '../components/ResponsiveContainer';
import Iso9001 from '../images/iso_9001.jpg';

const Company = () => {
    return (
        <ResponsiveContainer showBanner={false}>
        <Segment style={{ padding: '8em 0em' }} vertical >
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>

                    <Header as='h3' style={{ fontSize: '2em' }}>
                    HAKKIMIZDA
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>
                    Firmamız kuruluş itibarı ile sektörde bulunan nitelikli ürün üretme
                    ve çözüm odaklı çalışma prensibine yeni bir bakış açısı kazandırmak istemektedir.
                    Zamanın ve hammaddenin değerinin farkında olarak lazer kesim,
                    abkant büküm, kaynaklı imalat, proje bazlı işler ile tasarım alanında hem
                    yurtiçi hem de yurtdışı hizmet vermek amacı ile hareket etmektedir.
                    </p>
                </Grid.Row>
                <Grid.Row>

                    <Header as='h3' style={{ fontSize: '2em' }}>
                    MİSYON
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>
                    Kaliteli iş ve ürün üretme ilkesi ile hareket eden AKKA Demir Çelik,
                    taahhüt ettiği projeleri zamanında ve eksiksiz teslim etme misyonuna sahiptir.
                    Ulusal sanayi sektörünün dışa bağımlılığını azaltma çabası içerisinde olarak,
                    çözüm odaklı proje ve yaklaşımı ile sektöre yeni bir bakış açısı kazandırmayı amaç edinmektedir.
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                    VİZYON
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>
                        AKKA Demir Çelik son teknoloji ile tecrübeyi harmanlayarak ulusal ve uluslararası
                        tüm pazarlarda varolmayı hedefler. Bu doğrultuda kesintisiz imalat, verimli
                        çalışma ortamı ve teknoloji desteği ile katma değeri yüksek bir sanayi kuruluşu
                        olma vizyonunu ilke edinmektedir.
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                    SERTİFİKALARIMIZ
                    </Header>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column floated='left' width={5}>
                        <Image bordered rounded size='huge' src={Iso9001} />
                    </Grid.Column>
                    <Grid.Column  width={5}>
                    <Image bordered rounded size='huge' src={Iso9001} />
                    </Grid.Column>
                    <Grid.Column  floated='right' width={5}>
                    <Image bordered rounded size='huge' src={Iso9001} />
                    </Grid.Column>
                </Grid.Row>
                
            </Grid>
            </Segment>
        </ResponsiveContainer>
    )
}

export default Company;
