import React from 'react';
import { createMedia } from '@artsy/fresnel';
import {
   Sidebar
  } from 'semantic-ui-react';
  import PropTypes from 'prop-types'
import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      mobile: 0,
      tablet: 768,
      computer: 1024,
    },
  })

  /* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */

const ResponsiveContainer = ({ children, showBanner }) => (
    /* Heads up!
     * For large applications it may not be best option to put all page into these containers at
     * they will be rendered twice for SSR.
     */
    <MediaContextProvider>
        <Media greaterThan='mobile'>
            <DesktopContainer showBanner={showBanner}>{children}</DesktopContainer>
        </Media>
        <Media as={Sidebar.Pushable} at='mobile'>
            <MobileContainer showBanner={showBanner}>{children}</MobileContainer>
        </Media>
    </MediaContextProvider>
  )
  
  ResponsiveContainer.propTypes = {
    children: PropTypes.node,
  }

export default ResponsiveContainer;