import React, { useState, useEffect } from 'react';
import {
    Grid,
    Header,
    Image,
    Segment,
  } from 'semantic-ui-react';


import { CenteredContainer, FormContainer, Form, FormGroup, Label, Input, Button, WidAdj, Title,
         ResultsContainer, ContainerOne, ContainerTwo, ContainerInsideOne, ContainerInsideTwo,
          TitleBar, Title1,Subtitle1, Dropdown, Option} from '../components/AdminElements';
import AdminStatsCard from '../components/AdminStatsCard';
import styled from 'styled-components';
import {auth, fireStore} from '../firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";

// import Navbar from '../components/Navbar';
import ResponsiveContainer from '../components/ResponsiveContainer';

const Admin = () => {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [logged, setLogged] = useState(false);

    const handleLogout = () => {
        console.log("logging out")
        auth.signOut();
        setLogged(false);
    }

    const handleSubmit = async(e, email, password) => {
        e.preventDefault()
        console.log(email)
        console.log(password)

        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch (error) {
            console.log(error);
        }
        
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
           if (user) {
            setLogged(true);
           } else {
              setLogged(false);
           }
        });
        return unsubscribe
     }, []);
    
    return (
        <ResponsiveContainer showBanner={false}>
        <CenteredContainer>

        <FormContainer>
            {!logged ?
        
        (<WidAdj>
          <Title>Merhaba Admin</Title>
          <Form onSubmit={(e) => handleSubmit(e, email, password)}>
          <FormGroup>
              <Label>Email Adresiniz</Label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Şifre</Label>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
         
              <Button type="submit" >Gönder</Button>

          </Form>
        </WidAdj> ) 
        :
        <div>
            <Title>Admin Sayfasi</Title>
            <Button onClick={handleLogout}>Logout</Button>
        </div>
            }
        </FormContainer>
        </CenteredContainer>

        </ResponsiveContainer>
    )
}

export default Admin;
