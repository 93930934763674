import React, {useState, useEffect} from 'react';

import { useHistory } from "react-router-dom";
import {
    Button,
    Container,
    Menu,
    Header,
    Image
  } from 'semantic-ui-react';
import { useSiteData } from '../contexts/SiteDataContext';
import logo from '../images/logo_siyah_beyaz.jpg';

const Navbar = ({fixed}) => {

    const {activeItem, setActiveItem} = useSiteData();
    const history = useHistory();


    const handleItemClick = (e, { name }) => {
        setActiveItem(name);
        // console.log(currentUser)
        if (name === "home") {
          history.push(`/`);
        } else {
          history.push(`/${name}`);
        }
      };

      const handleButtonClick = (e, name) => {
        // setActiveButton({ activeItem: name });
        // console.log(name);
        // if (name === "login" && currentUser) {
        if (name === "login" ) {
        //   logout();
          history.push(`/login`);
        } else if (name === "home") {
          history.push(`/`);
        } else {
          history.push(`/${name}`);
        }
      };

    return (
  //     'red'
  // | 'orange'
  // | 'yellow'
  // | 'olive'
  // | 'green'
  // | 'teal'
  // | 'blue'
  // | 'violet'
  // | 'purple'
  // | 'pink'
  // | 'brown'
  // | 'grey'
  // | 'black'
            <Menu
                fixed={fixed ? 'top' : null}
                inverted={!fixed}
                pointing={!fixed}
                secondary={!fixed}
                size='massive'
                color='gray'
                // style={{border: "3px solid green"}}
                // style={{border: "1px solid white"}}
              //   'mini'
              // | 'tiny'
              // | 'small'
              // | 'medium'
              // | 'large'
              // | 'big'
              // | 'huge'
              // | 'massive'
                >
                    <Container style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '1em' }}>
                        <Image src={logo} size='medium' alt='Company Logo' />
                      </div>
                      <div>
                        <Header inverted={!fixed} as='h1'>AKKA DEMİR ÇELİK</Header>
                      </div>
                    </Container>
                    
                    <Container >
                        <Menu.Item as='a' name = "home" onClick={handleItemClick} active={activeItem === 'home'}>
                            
                            <Header inverted={!fixed} as='h2'>Ana Sayfa</Header>
                        </Menu.Item>
                        <Menu.Item as='a' name = "company" onClick={handleItemClick} active={activeItem === 'company'}>
                            <Header inverted={!fixed} as='h2'>Şirket</Header>
                        </Menu.Item>
                        <Menu.Item as='a' name = "research" onClick={handleItemClick} active={activeItem === 'research'}>
                            <Header inverted={!fixed} as='h2'>Bilgi</Header>
                        </Menu.Item>
                        <Menu.Item as='a' name = "services" onClick={handleItemClick} active={activeItem === 'services'}>
                            <Header inverted={!fixed} as='h2'>Hizmetler</Header>
                        </Menu.Item>
                        {/* <Menu.Item as='a' name = "team" onClick={handleItemClick} active={activeItem === 'team'}>
                            <Header inverted={!fixed} as='h2'>Takim</Header>
                        </Menu.Item> */}
                        <Menu.Item as='a' name = "contact" onClick={handleItemClick} active={activeItem === 'contact'}>
                            <Header inverted={!fixed} as='h2'>İletişim</Header>
                        </Menu.Item>
                        <Menu.Item position='right'>
                        {/* <Button as='a' onClick={(e)=>{handleButtonClick(e, 'login')}} inverted={!fixed}>
                            Log in
                        </Button>
                        <Button as='a' onClick={(e)=>{handleButtonClick(e, 'signup')}}  inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }}>
                            Sign Up
                        </Button> */}
                        </Menu.Item>
                    </Container>
            </Menu>
    )
}

export default Navbar
