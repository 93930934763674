import React, {useMemo} from 'react';
import { Container, Header, Form, Button, Grid, Icon } from 'semantic-ui-react';
import ResponsiveContainer from '../components/ResponsiveContainer';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
const Contact = () => {
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const mapStyles = {
    height: '50vh',
    width: '100%',
  };

  const boxStyle = {
    // border: '1px solid #ccc',
    // borderRadius: '5px',
    padding: '10px',
    textAlign: 'start',
    width: 'fit-content',
    margin: '0 auto',
  };

  const iconStyle = {
    marginRight: '0px',
  };

  function Map(){
    const center = useMemo(()=> ({ lat: 40.198375, lng: 28.922428 }), []);
    return (<GoogleMap mapContainerStyle={mapStyles} zoom={15} center={center}>
        <Marker position={center}/>
      </GoogleMap>)
  }

  return (
    <ResponsiveContainer>
      <Container style={{ marginTop: '5vh'}}>
        <Header as='h1'>Contact Us</Header>
        <Form>
          <Form.Field>
            <label>Isim</label>
            <input placeholder='Name' />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input type='email' placeholder='Email' />
          </Form.Field>
          <Form.Field>
            <label>Mesaj</label>
            <textarea placeholder='Message' rows={5} />
          </Form.Field>
          <Button primary type='submit'>Gonder</Button>
        </Form>
      </Container>
      <Container style={{ marginTop: '5vh', width: '24vw'}}>
      <Grid style={boxStyle} divided padded={false}>
        <Grid.Row >
          <Grid.Column width={1} textAlign='center'>
            <Icon name="address card" style={iconStyle}/>
          </Grid.Column>
          <Grid.Column width={10} textAlign='center'>
            Alaaddinbey Nilüfer , Bursa, Turkey
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={1}>
            <Icon name="phone" style={iconStyle}/>
          </Grid.Column>
          <Grid.Column width={10}>
            +90 532 794 79 63
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={1}>
            <Icon name="mail" style={iconStyle}/>
          </Grid.Column>
          <Grid.Column width={10}>
            info@akkademircelik.com
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </Container>
      <Container style={{ marginTop: '2vh', marginBottom: '5vh' }}>
        {isLoaded?
      <Map />:<></>}
      </Container>
    </ResponsiveContainer>
  );
};

export default Contact;

