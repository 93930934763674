import React from 'react';

// import Navbar from '../components/Navbar';
import ResponsiveContainer from '../components/ResponsiveContainer';

const Profile = () => {
    return (
        <ResponsiveContainer>
            Profile
        </ResponsiveContainer>
    )
}

export default Profile;
