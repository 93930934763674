import React from 'react';
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Image,
    Segment,
  } from 'semantic-ui-react';

// import Navbar from '../components/Navbar';
import ResponsiveContainer from '../components/ResponsiveContainer';

const Research = () => {
    return (
        <ResponsiveContainer showBanner={false}>
        <Segment style={{ padding: '8em 0em' }} vertical >
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                    Lazer Kesim
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>
                    Lazerle kesme ısıl bir işlem olup, metalin ergitilip buharlaştırılması ve kimyasal olarak indirgenmesi
                    esasına dayanır. Lazer ışınları diğer ışınlardan farklı olarak yüksek yönlenme özelliğine, yüksek güç
                    yoğunluğuna ve iyi odaklanma özelliğine sahiptirler. Günümüzde ışınlar, göz cerrahisinden telefon
                    teknolojisine ve metal işlemeye kadar hemen hemen her alanda kullanılmaktadır. Bu doğrultuda
                    özellikle sanayi faaliyetlerde hızlı üretim imkânı sağlayarak hem para hem de zamandan tasarruf
                    edilmesine yol açmıştır.
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    Eski tip diye tabir edilen karbondioksit lazer tezgahlarında lazer, karbondioksit gazına elektrik akımı
                    verilerek oluşturulur. Bunun yanında kullanılan azot ve helyum gazı düşük verimde olan karbondioksit
                    lazerine eklenerek verim %30 arttırılmaktadır. Lazer ışının tezgahın rezonatör bölümünde cam tüpleri
                    içinde 10 m2 ′ye yakın mesafe kateder. Bu tüplerden gaz geçerken iki ucu arasından elektrik akımı
                    verilerek lazer oluşturulur. Lazerin bir ışın olması sebebiyle aynalar sayesinde yönleri
                    değiştirilebilmektedir. En son olarak lazer ışını kesme kafasına gelmekte burada kesme işlemi
                    yapılmaktadır.
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    Günümüzde fiber lazer teknolojisinin gelişmesiyle, karbondioksit kullanımlı büyük ebatlı
                    rezenatörlere ihtiyaç kalmamıştır. Bu durum hem mekan anlamında avantaj sağlarken enerji
                    anlamında da ortalama yarı yarıya bir verimlilik oluşturmuştur. Ayrıca karbondioksit lazer
                    tezgahlarına oranla daha hızlı ve kaliteli işleme yapması nedeniyle fiber lazer teknolojisi oldukça
                    öndedir.
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    Lazerle işlemede optik penetrasyon derinliği malzeme ve lazer dalga boyuna, termal difüzyon derinliği
                    ise malzeme özelliklerine bağlıdır. Operasyon, ısıl işlem sonucu meydana geldiği için işlenen yüzey
                    üzerinde yeni bir tabaka oluşur ve ayrıca parçada ısıdan etkilenmiş bir bölge meydana gelir. Bu durum
                    malzemenin mekanik özelliklerini bölgesel olarak etkiler. Lazerle, metal ve metal olmayan
                    malzemeler işlenebilir.
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    Ayrıca metallerde kesme, şekillendirme, kaplama yapma, temizleme, aşınmış kısımların doldurulması,
                    ısıl işlem, mikro işleme, markalama ve sinterleme işlemlerinde kullanılabilir. Lazerle işleme yapan
                    CNC’li tezgahlarda çok hassas işler elde edilebilir. Lazer ışınlarının insan sağlığına zararları nedeniyle
                    çalışma esnasında gerekli emniyet tedbirleri alınmalıdır. Kontrolsüz ve iş sağlığı, güvenliği tedbirleri
                    alınmadığında ciddi yaralanmalara ve uzuv kayıplarına neden olabileceği unutulmamalıdır.
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                    Lazer kesim teknolojisinin avantajları;
                    </p>
                    <ul style={{ fontSize: '1.33em' }}>
                        <li>Lazer kesim teknolojisi ile yüksek işleme hızına ulaşılmakta ve birim maliyet düşerken kalitesinin
                    artması</li>
                    <li> Parça imalatında kalıp maliyeti yoktur.</li>
                    <li> Aynı plaka sac üzerinden birbirinden farklı parçalar işlenebilir.</li>
                    <li> Fire oranı minimum seviyeye indirilir.</li>

                    <li> İşlenecek parçaların uygun olması durumunda ortak yüzeyler beraber kesilerek maliyet azaltılabilir.</li>
                    <li> Çapaksız bir kesim sağlanır ve ısı deformasyonu minimum seviyedir. Ek işlem gereksinimi yoktur</li>
                    <li> Sac deformasyonları yoktur</li>
                    <li> Sac kalınlığının yarısı çapındaki delikler işlenebilir</li>
                    <li> Her türlü yazı, resim, amblem sac üzerinden kesilebilir ve markalanabilir.</li>
                    <li> 0,05 mm hassasiyet ile parçalar kesilebilir.</li>
                    <li> Lazerin çeşitli tezgahlarda uygulanmasıyla üretim 24 saat yapılabilmektedir.</li>
                    </ul>
                </Grid.Row>
                
            </Grid>
            </Segment>
        </ResponsiveContainer>
    )
}

export default Research;
