import React from 'react';
// import Navbar from '../components/Navbar';
import ResponsiveContainer from '../components/ResponsiveContainer';

const Team = () => {
    return (
        <ResponsiveContainer>
            Team
        </ResponsiveContainer>
    )
}

export default Team;
