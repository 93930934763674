import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from './pages/Home';
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Services from "./pages/Services"; 
import Profile from "./pages/Profile";
import Company from "./pages/Company";
import Research from "./pages/Research";
import Admin from './pages/Admin';
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

import { SiteDataProvider } from "./contexts/SiteDataContext";

function App() {
  return (
    <SiteDataProvider>
      <Router>
        {/* <Navbar /> */}
        <Switch>
          <Route exact path="/">
          <Home />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/company">
            <Company />
          </Route>
          <Route path="/research">
            <Research />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
        </Switch>
        <Footer />
      </Router>
      </SiteDataProvider>
  );
}

export default App;
