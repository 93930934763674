import React, { useContext, useState, useEffect } from 'react';


const SiteDataContext = React.createContext();

export function useSiteData() {
  return useContext(SiteDataContext);
}


export function SiteDataProvider({ children }) {
  const [activeItem, setActiveItem] = useState(['home']);



  useEffect(() => {
    setActiveItem('home')
  }, [])

  const value = {
    activeItem,
    setActiveItem
  }


  return (
    <SiteDataContext.Provider value={value}>
      {children}
    </SiteDataContext.Provider>
  )
}